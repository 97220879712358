import { Injectable, inject } from '@angular/core';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';
import { Observable, of } from 'rxjs';
import { Token } from '../../models/token.model';

@Injectable({ providedIn: 'root' })
export class LocalStorageJwtService {
  private readonly key = 'display_token' as const;
  private readonly localStorage = inject(WA_LOCAL_STORAGE);

  getItemSync(): Token | null {
    const data = this.localStorage.getItem(this.key);
    if (data) {
      return new Token(data);
    }

    return null;
  }

  getItem(): Observable<Token | null> {
    const data = this.getItemSync();
    if (data) {
      return of(data);
    }
    return of(null);
  }

  setItem(token: string): Observable<string> {
    if (token) {
      this.localStorage.setItem(this.key, token);
    }
    return of(token);
  }

  removeItem(): Observable<boolean> {
    this.localStorage.removeItem(this.key);
    return of(true);
  }
}
