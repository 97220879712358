import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { WA_NAVIGATOR } from '@ng-web-apis/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiURL } from '../../tokens';

export type HttpParamsType =
  | HttpParams
  | {
      [param: string]:
        | string
        | number
        | boolean
        | ReadonlyArray<string | number | boolean>;
    };

/**
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({
  providedIn: 'root',
})
export class RestService {
  public readonly _http = inject(HttpClient);
  public readonly navigator = inject(WA_NAVIGATOR);
  public readonly apiUrl: string = inject(ApiURL);

  /**
   * Cabeceras
   *
   * @returns {HttpHeaders}
   */
  private getHeaders(): HttpHeaders {
    const lang = this.navigator.language || 'es';

    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': lang,
    };

    return new HttpHeaders(headersConfig);
  }

  /**
   * Handler de error
   *
   * @returns {any}
   * @param res
   */
  private formatErrors(res: HttpResponse<any> | any) {
    const error: any = res || res.body || {};
    return throwError(() => error);
  }

  /**
   * Envia una petición post
   *
   * @param path
   * @param body
   * @param params
   * */
  post<T>(
    path: string,
    body: T,
    params: HttpParamsType = new HttpParams(),
  ): Observable<HttpResponse<{ data?: any }>> {
    return this._http.post(`${this.apiUrl}${path}`, body, {
      observe: 'response',
      headers: this.getHeaders(),
      params,
    });
  }

  /**
   * Envia una petición put
   *
   * @param path
   * @param body
   * */
  put<T, R = unknown>(
    path: string,
    body: T,
  ): Observable<HttpResponse<{ data?: R }>> {
    return this._http.put(`${this.apiUrl}${path}`, body, {
      observe: 'response',
      headers: this.getHeaders(),
    });
  }

  /**
   * Envia una petición get
   *
   * @param path
   * @param params
   * @param cache boolean or time in seconds
   * */
  get(
    path: string,
    params: HttpParamsType = new HttpParams(),
  ): Observable<HttpResponse<{ data?: any }>> {
    if (!params) {
      params = new HttpParams();
    }

    return this._http
      .get(`${this.apiUrl}${path}`, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
        responseType: 'json',
      })
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  /**
   * Envia una petición delete
   *
   * @param path
   * @param params
   * */
  delete<R = unknown>(
    path: string,
    params: HttpParamsType = new HttpParams(),
  ): Observable<HttpResponse<{ data?: R }>> {
    if (!params) {
      params = new HttpParams();
    }
    return this._http
      .delete(`${this.apiUrl}${path}`, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
      })
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  /**
   * Envia una petición patch
   *
   * @param path
   * @param params
   * */
  patch<T, R = unknown>(
    path: string,
    body: T,
    params: HttpParamsType = new HttpParams(),
  ): Observable<HttpResponse<{ data?: R }>> {
    if (!params) {
      params = new HttpParams();
    }
    return this._http
      .patch(`${this.apiUrl}${path}`, body, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
      })
      .pipe(catchError(this.formatErrors.bind(this)));
  }
}
