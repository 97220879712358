import { Metadata } from '../types';
import { GeoJSONAPIResponse } from '../types/geoJSON.type';

export function transformGeoJSONToMetadata(
  geoJSON: GeoJSONAPIResponse | null,
): Metadata {
  return {
    timezone: geoJSON?.timezone,
    region: geoJSON?.region,
    organization_name: geoJSON?.organization_name,
    country_code: geoJSON?.country_code,
    organization: geoJSON?.organization,
    latitude: geoJSON?.latitude,
    longitude: geoJSON?.longitude,
    ip: geoJSON?.ip,
    city: geoJSON?.city,
    country: geoJSON?.country,
    continent: geoJSON?.continent_code,
  };
}
