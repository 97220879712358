export class Token {
  private payload: string | null = null;

  constructor(private readonly token: string) {
    try {
      this.payload = this.decodeJwtPayload(this.token);
    } catch (e) {}
  }

  public isValid(): boolean {
    return (
      !!this.payload && !!this.token && this.getTokenExpDate() > new Date()
    );
  }

  public getValue(): string {
    return this.token;
  }

  public toString() {
    return this.getValue();
  }

  public getPayload(): any {
    return this.payload;
  }

  /**
   * Returns expiration date
   * @returns Date
   */
  public getTokenExpDate(): Date {
    const decoded = this.getPayload();
    if (!decoded?.exp) {
      // Devolvemos una fecha superior a la de hoy
      return new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp); // 'cause jwt token are set in seconds
    return date;
  }

  private decodeJwtPayload(payload: string): any {
    if (!payload) {
      return null;
    }

    if (payload.length === 0) {
      throw new Error('Cannot extract from an empty payload.');
    }

    const parts = payload.split('.');

    if (parts.length !== 3) {
      throw new Error(
        `The payload ${payload} is not valid JWT payload and must consist of three parts.`
      );
    }

    let decoded;
    try {
      decoded = this.urlBase64Decode(parts[1]);
    } catch (e) {
      throw new Error(
        `The payload ${payload} is not valid JWT payload and cannot be parsed.`
      );
    }

    if (!decoded) {
      throw new Error(
        `The payload ${payload} is not valid JWT payload and cannot be decoded.`
      );
    }
    return JSON.parse(decoded);
  }

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), (c: any) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  private b64decode(str: string): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error(
        `'atob' failed: The string to be decoded is not correctly encoded.`
      );
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer &&
      ((bs = bc % 4 ? bs * 64 + buffer : buffer),
      // and if not first of each 4 characters,
      // convert the first 8 bits to one ascii character
      bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }
}
