import { InjectionToken } from '@angular/core';

export const ApiURL = new InjectionToken<string>('ApiURL');
export const WebSocketsURL = new InjectionToken<string>('WebSocketsURL');

export const AnalyticsUrlProxy = new InjectionToken<string>(
  'AnalyticsUrlProxy',
);

export const AdmefyHomeURL = new InjectionToken<string>('AdmefyHomeURL');
