import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, timeout } from 'rxjs';
import { transformGeoJSONToMetadata } from './transformers/geoJSONToMetadata';
import { transformIpapiResponse } from './transformers/ipapiToMetadata';
import { transformMicrolinkToMetadata } from './transformers/microlinkToMetadata';
import {
  GeoJSONAPIResponse,
  GeoLocationMicrolink,
  IpapiResponse,
  Metadata,
} from './types';

/**
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class MetadataService {
  private readonly _http = inject(HttpClient);

  private cache: Metadata | undefined = undefined;

  public getGeoData(): Observable<Metadata> {
    if (this.cache) {
      return of(this.cache);
    }

    return this.tryGetGeoData<GeoLocationMicrolink>(
      'https://geolocation.microlink.io',
    ).pipe(
      map((data) => transformMicrolinkToMetadata(data)),
      catchError(() =>
        this.tryGetGeoData<GeoJSONAPIResponse>(
          'https://get.geojs.io/v1/ip/geo.json',
        ).pipe(
          map((data) => transformGeoJSONToMetadata(data)),
          catchError(() =>
            this.tryGetGeoData<IpapiResponse>('https://ipapi.co/json/').pipe(
              map((data) => transformIpapiResponse(data)),
              catchError(() => of({} as Metadata)),
            ),
          ),
        ),
      ),
      tap((data) => (this.cache = data)),
    );
  }

  private tryGetGeoData<T>(url: string): Observable<T> {
    return this._http.get<T>(url).pipe(timeout(5000));
  }
}
